import '@/css/app.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { swiperDefault } from './tools'

import 'img-comparison-slider'

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)
window.Alpine = Alpine
import { initMobileMenu } from './alpinejs/Menu'

// App main
const main = async () => {
    initMobileMenu()

    Alpine.store('global', {
        section: 'header',

        get inSection() {
            // console.log('inSection: ' + this.section)
        },

        getRandomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min
        },

        toasts: []
    })

    Alpine.start()

    swiperDefault('.swiperDefault')

    // Dit komt overeen met de twig var blockHandle
    /*const blocks = ['text']

    blocks.forEach((block) => {
        const loadJs = async () => {
            const blockElements = document.querySelectorAll(`.${block}`)

            if (blockElements.length > 0) {
                let { init } = await import(`./blocks/${block}.js`)

                blockElements.forEach((block) => {
                    init(block)
                })
            }
        }
        loadJs()
    })*/

    // const app = createApp({})

    // app.component('confetti', ConfettiParty)

    // if (document.querySelectorAll('.js-langDropdown').length > 0) {
    //     const LangDropdown = defineAsyncComponent(() =>
    //         import('@/vue/components/LangDropdown.vue')
    //     )
    //     app.component('lang-dropdown', LangDropdown)
    // }

    // app.mount('#wrapper')
}

main().then(() => {
    console.info('Vite <3: All components loaded')
})

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.info('HMR')
    })
}
