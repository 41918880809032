// import { gsap, Power2, Power3 } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

import Swiper, {
    EffectCreative,
    Navigation,
    Pagination,
    Autoplay
} from 'swiper'

// swiper

export const swiperDefault = (selector) => {
    const swiperDefault = document.querySelectorAll('.swiperDefault')

    swiperDefault.forEach((swiper, index) => {
        new Swiper(swiper, {
            modules: [Navigation, Pagination, EffectCreative, Autoplay],
            pagination: {
                el: swiper
                    .closest('.section')
                    .querySelector('.swiper-pagination'), //'.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: swiper
                    .closest('.section')
                    .querySelector('.swiper-button-next'), //swiper.closest('.section').querySelector('.swiper-button-next')
                prevEl: swiper
                    .closest('.section')
                    .querySelector('.swiper-button-prev')
            },
            /*autoplay: {
                delay: 5000
            },*/
            grabCursor: true,
            speed: 640,
            effect: 'creative',
            /*creativeEffect: {
        prev: {
          shadow: true,
          translate: ['-20%', 0, -1]
        },
        next: {
          translate: ['100%', 0, 0]
        }
      },*/
            /*creativeEffect: {
                prev: {
                    shadow: true,
                    translate: [0, 0, -800],
                    rotate: [180, 0, 0]
                },
                next: {
                    shadow: true,
                    translate: [0, 0, -800],
                    rotate: [-180, 0, 0]
                }
            },*/
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: [0, 0, -400]
                },
                next: {
                    translate: ['100%', 0, 0]
                }
            },
            on: {
                //init: () => setTimeout(() => console.log('swiperDefault ready'), 2000) //swiper.classList.add('show')
            }
        })
    })
}
