export const initMobileMenu = () => {
    // console.log('initMobileMenu')
    // First, check if this component has to be initialized
    if (hasMenu()) {
        // then, fire it up!
        start()
    }
}

/**
 * Checks if page has autocomplete component
 * @return {Boolean}
 */
const hasMenu = () => {
    return true //document.getElementsByClassName('menuMobile').length > 0
}

// Start our component
const start = () => {
    Alpine.store('menuMobileVisible', {
        on: false,

        toggle() {
            this.on = !this.on
        }
    })
}
